<template>
  <div class='container'>
    <div class="box">
      <div class="box-title">二级预警名单</div>
      <div class="tips">学校层面应给予重点关注；当确认心理危机进一步升级时，按照三级预警处置，未达到三级预警的学生采用干预三大任务进行学生心理辅导</div>
    </div>
    <div class="content-box" style="background-color: #FEF9F3;">
        <div class="title" style="color: #FB6D3F;">学生信息</div>
        <p>姓名：{{risk.trueName}} &nbsp; <span style="margin-left: 10.6667vw;">校区：{{ risk.area ? risk.area : "未填写" }}</span></p>
        <p style="margin-top: 2.6667vw;">年级：{{
                risk.grade !== null && risk.grade !== -1
                  ? (gradeList[risk.grade]?gradeList[risk.grade].gradeName:'')
                  : ""
              }} &nbsp; <span style="margin-left: 10.6667vw;">班级：{{ risk.studyClass }}</span></p>
    </div>
    <div class="content-box" v-if="this.level==1" style="background-color: #EFF9F8;">
        <div class="title" style="color:#5EDBC9">干预方案-任务一</div>
        <p style="font-size: 4vw;">约谈步骤</p>
        <div class="step" v-for="(item,index) in procedure" :key="item.id">
          <p class="step-title">{{item.title}}</p>
          <p class="text">{{item.details}}<span style="color: #10CA9B;">{{ item.details2 }}</span> {{ item.details3 }} </p>
          <div class="btns" v-if="item.id===5">
            <button @click="isDialog=true">下载危机应对卡</button>
            <van-uploader
              :action="uploadUrl"
              :after-read="afterRead"
              :name="1">
               <button class="btn2" >上传危机应对卡</button>
             </van-uploader>
          </div>
          <div class="btns" v-if="item.id===7">
            <button @click="isDialog=true">下载爱护生命约定</button>
            <van-uploader
              :action="uploadUrl"
              :after-read="afterRead"
              :name="2">
               <button class="btn2">上传爱护生命约定</button>
             </van-uploader>
          </div>
          <img class="arrow" src="@/assets/project/arrow.png" alt="" v-if="index!==procedure.length-1">
        </div>
    </div>
    <div class="content-box" v-if="this.level==2">
        <div class="title">干预方案-任务二</div>
        <p style="font-size: 4vw;">通知班主任和家长</p>
        <div class="step" v-for="(item,index) in procedure2" :key="item.id">
          <p class="step-title">{{item.title}}</p>
          <p class="text">{{item.details}}<span style="color: #10CA9B;">{{ item.details2 }}</span> {{ item.details3 }} </p>
          <div class="btns" v-if="item.id===1">
            <button @click="$router.push({path:'/record/info',query:{id:risk.id}})">完善学生信息</button>
          </div>
          <div class="btns" v-if="item.id===2">
            <button @click="isDialog=true">下载家长告知书</button>
            <van-uploader
              :action="uploadUrl"
              :after-read="afterRead"
              :name="4">
               <button class="btn2">上传家长告知书</button>
             </van-uploader>
          </div>
          <img class="arrow" src="@/assets/project/arrow.png" alt="" v-if="index!==procedure2.length-1">
        </div>
    </div>
    <div class="content-box" v-if="this.level==3">
        <div class="title">干预方案-任务三</div>
        <p style="font-size: 4vw;">持续跟踪</p>
        <div class="step" v-for="(item,index) in procedure3" :key="item.id">
          <p class="step-title">{{item.title}}</p>
          <p class="text">{{item.details}}<span style="color: #10CA9B;">{{ item.details2 }}</span> {{ item.details3 }} </p>
          <div class="btns" v-if="item.id===2">
            <button @click="isDialog=true">下载快乐习惯清单</button>
            <van-uploader
              :action="uploadUrl"
              :after-read="afterRead"
              :name="3">
               <button class="btn2">上传快乐习惯清单</button>
             </van-uploader>
          </div>
          <img class="arrow" src="@/assets/project/arrow.png" alt="" v-if="index!==procedure2.length-1">
        </div>
    </div>
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/dialog-bg.png"  style="width: 40vw;height: 40vw;margin: 5.3333vw 0 4vw 0;"/>
        <p>移动端暂未开放评估功能，</p>
        <p>如需对学生进行心理评估请使用PC端进行评估</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {riskDetail} from '@/api/warning.js'
import {addAccessory,upload} from '@/api/user.js'
export default {
  data(){
    return{
      risk:{},
      level:null,
      isDialog:false,
      form: {
          orgStudentId: '',
          title: '',
          fileName: '',
          type: '', // 1 危机应对卡 2 爱护生命约定 3 快乐习惯清单 4 学生心理健康状况告知书
          fromId: ''
      },
      uploadUrl:'https://pmtadmin3.uelink.com.cn/orgAdmin/upload',
      gradeList: [
            {
              grade: 0,
              gradeName: '一年级'
            },
            {
              grade: 1,
              gradeName: '二年级'
            },
            {
              grade: 2,
              gradeName: '三年级'
            },
            {
              grade: 3,
              gradeName: '四年级'
            },
            {
              grade: 4,
              gradeName: '五年级'
            },
            {
              grade: 5,
              gradeName: '六年级'
            },
            {
              grade: 6,
              gradeName: '初一'
            },
            {
              grade: 7,
              gradeName: '初二'
            },
            {
              grade: 8,
              gradeName: '初三'
            },
            {
              grade: 9,
              gradeName: '高一'
            },
            {
              grade: 10,
              gradeName: '高二'
            },
            {
              grade: 11,
              gradeName: '高三'
            }
      ],
      procedure: [
          {
            id: 1,
            title: '第一步：明确问题',
            details: '沉着冷静地与来访者谈论，直接面对的提问能够让来访者减轻心理负担，并且感受到被关爱。类似问法有：',
            details2: '“我们在之前的评估中发现你多数时间闷闷不乐。最近发生了什么事情，让你不像 其他同学一样乐观开朗呢？”'
          },
          {
            id: 2,
            title: '第二步：疏导情绪',
            details: '肯定来访者的困境，接纳来访者的情绪，帮助他把情绪宣泄出来。',
            details2: '“经历了这么多事情，你一定感觉非常痛苦。”“要承受住刚刚你说的这些，一定非常困难。”'
          },
          {
            id: 3,
            title: '第三步：给予理解和支持',
            details: '通过对话，与来访者建立信任的关系，并提供理解、接纳与支持，让来访者感觉到永远有人在关心着他。',
            details2: '“我关心你，并且想为你提供帮助”“我一直在这里。”'
          },
          {
            id: 4,
            title: '第四步：寻找应对方法',
            details: '理清自杀行为与问题解决之间的关系，帮助来访者发现更多可能性。',
            details2: '“其实自杀并不能帮你解决问题。既然这件事让你这么痛苦，那我们一起来看看究竟可以做些什么。”“你之前遇到痛苦的时候，都是怎么做的呢？”'
          },
          {
            id: 5,
            title: '第五步：填写应对卡',
            details: '下载打印危机应对卡，让来访者知晓下次危机出现时能够且需要采取的步骤，包括联系哪些人，拨打什么热线等。',
            details2: '“那接下来我们需要填写一张卡，来帮助你在下一次遇到困难时更好地渡过难关。”',
            details3: '填写完成的危机应对卡拍照上传学生心理档案。'
          },
          {
            id: 6,
            title: '第六步：制订计划',
            details: '和来访者讨论后续的计划，表明自己会突破保密协议，通知班主任和家长。如果有在学校，那要增加访谈的频率，至少一周一次，严重则可以一周两次。'
          },
          {
            id: 7,
            title: '第七步：做出保证',
            details: '下载爱护生命约定书和来访者一同填写“爱护生命约定”，并且要求画押保证，完成后拍照上传学生心理档案中。'
          }
      ],
      procedure2: [
          {
            id: 1,
            title: '第一步：通知班主任',
            details: '向班主任收集学生的资料，完善学生信息档案；组织班上的学生形成朋辈支援小组，以帮助当事人建立寻求协助的技巧，以及随时提供相应的资源；若面对有上升至高风险趋势的个案，在校期间必须保证24小时的看护。'
          },
          {
            id: 2,
            title: '第二步：通知家长',
            details: '下载家长的《学生心理健康状况告知书》，以文档形式下发家长并要求家长签字确认，确认后拍照上传告知书，且明确告知家长学生的心理状态和风险，建议家长带孩子到专业机构求助，并且根据需要进行药物治疗。'
          }
      ],
      procedure3: [
          {
            id: 1,
            title: '个案在校',
            details: '必须持续跟踪，定期评估自杀风险和心理状态，并按照上面的计划进行约谈和咨询。'
          },
          {
            id: 2,
            title: '个案离校',
            details: '定期评估，更新心理档案。若有需要，在干预后期，学生危机水平下降之后，可让学生每天填写“快乐习惯清单”，来帮助其保持良好的心情，可跟进表单完成情况，完成后上传学生心理档案'
          }
      ],
    }
  },
  created(){
    this.getDetail()
    this.level=this.$route.query.task
    this.form.fromId=this.$route.query.id*1
  },
  methods:{
    async getDetail(){
      const {id} = this.$route.query
      const res = await riskDetail({id})
      this.risk=res.data.risk
      this.form.orgStudentId=this.risk.orgStudentId
    },
    // 读取文件函数
    async afterRead(file,detail){
      // console.log(formData);
      console.log(file);
      const formData = new FormData();
      // 通过append方法添加需要的file
      // 这里需要注意 append(key, value)来添加数据，如果指定的key不存在则会新增一条数据，如果key存在，则添加到数据的末尾
      formData.append('file', file.file);
      formData.append('type','fileOrgWork');
      const res = await upload(formData)
      this.form.fileName = res.data.filePath
      this.form.title = file.file.name
      this.form.type=detail.name
      if (res.code === 0) {
        const res2=await addAccessory(this.form)
        this.$toast.success('上传成功')
      } else {
          this.$toast.fail('上传失败了哦，请查看导入模板是否有误！')
      }
    },
    
  }
}
</script>
<style lang='less' scoped>
.container{
  padding:5.3333vw 0 4vw 0;
  min-height: 100%;
  background-color: #fff;
  .box{
    .box-title{
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 4vw;
      font-size: 4.2667vw;
      font-weight: 550;
      color: #FB6D3F;
      // border-left: .8vw solid #FB6D3F;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: .8vw;
        height: 4vw;
        border-radius: .8vw;
        background-color: #FB6D3F;
      }
    }
    .tips{
      padding: 2.6667vw 4vw 0 4vw;
      color: #666;
      line-height: 5.6vw;
      font-size: 3.4667vw;
    }
  }
  .content-box{
    margin: 4vw 4vw 0 4vw;
    padding: 4vw;
    border-radius: 4vw;
    background-color: #EFF9F8;
    font-size: 3.7333vw;
    .title{
      color:#5EDBC9;
      font-weight: 550;
      font-size: 4.2667vw;
      margin-bottom: 2.6667vw;
    }
    .step{
      position: relative;
      margin-top: 8vw;
      padding: 2.6667vw 0;
      display: flex;
      // height: 40vw;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 2.6667vw;
      background-color: #fff;
      .step-title{
        align-self: flex-start;
        flex-shrink: 0;
        font-size: 2.9333vw;
        margin-bottom: 4vw;
        padding: 0 3.4667vw;
        // width: 32vw;
        height: 5.6vw;
        color: #fff;
        line-height: 5.6vw;
        background: #FB6D3F;
        border-radius: 0vw 5.3333vw 5.3333vw 0vw;
      }
      .text{
          padding: 0 4vw;
          line-height: 5.3333vw;
          font-size: 3.2vw;
       }
      .arrow{
        position: absolute;
        left: 50%;
        width: 4vw;
        height: 4vw;
        bottom: -6.1333vw;
        transform: translateX(-50%);
      }
      .btns{
        margin-top: 4vw;
        padding: 0 4vw;
        display: flex;
        justify-content: flex-end;
        .btn2{
          margin-right: 0;
          color: #fff;
          background-color: #5EDBC9;
        }
        button{
          margin-right: 2.6667vw;
          flex: 1;
          padding: 0 2.6667vw;
          height: 9.6vw;
          line-height: 9.6vw;
          color: #5EDBC9;
          border-color: #5EDBC9 ;
          border-radius: 13.3333vw;
          background-color: transparent;
          border: .2667vw solid #5EDBC9;
          &:nth-of-type(2){
            margin-right: 0;
            background-color: #fff;
            background-color: #5EDBC9;
          }
        }
      }
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      line-height: 6.4vw;
    }
    button{
      margin: 8vw;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #000;
    }
  }
}
</style>